import { Calendar, luxonLocalizer, Views } from "react-big-calendar";
import { DateTime } from "luxon";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box, Grid } from "@mui/material";
import { NavigateNextTwoTone } from "@mui/icons-material";

const localizer = luxonLocalizer(DateTime);

const events = [
  {
    id: 0,
    title: "Test Event",
    start: new Date(2022, 9, 17, 0, 0, 0),
    end: new Date(2022, 9, 17, 12, 0, 0),
    resource: 0,
    link: "https://google.com",
  },
];

function handleView(event: {}) {
  // do something here for rendering the view
}

function handleEventDoubleClick(event: {
  id: number;
  title: string;
  start: Date;
  end: Date;
  resource: number;
}) {
  // navigate browser to link
  window.location.href = events.find((e) => e.id === event.id)?.link || "";
}

export function EventCalendar() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="600px"
      height="80%"
      // minWidth="600px"
    >
      <Calendar
        style={{ width: "80%" }}
        localizer={localizer}
        view={Views.MONTH}
        onView={handleView}
        events={events}
        onDoubleClickEvent={handleEventDoubleClick}
      />
    </Box>
  );
}
