import React, { useContext, useReducer } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  gql,
  useQuery,
} from "@apollo/client";
import type { RootState } from "../../store";
import { useAppSelector, useAppDispatch } from "../../hooks";
import {
  increment,
  decrement,
  incrementByAmount,
} from "../../features/pricingTool/pricingToolSlice";
import { setContext } from "@apollo/client/link/context";
import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import HospitalPriceEditor from "./HospitalPriceEditor";
import PricingCampaignPage from "./PricingCampaignPage";
import { Route, Routes } from "react-router-dom";

const httpLink = createHttpLink({
  uri: "https://wise-sunbeam-66.hasura.app/v1/graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const session = JSON.parse(
    // TODO this is a bit of a magic link. Need to update this from the supabase prod URL.
    localStorage.getItem("sb-ymonfqkwqxgefhvoxodl-auth-token") || ""
  );
  const token = session.access_token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const mockData = {
  data: {
    evo_pricing_pricing_with_usage: [
      {
        hospital_id: "H0001",
        code: "2305",
        product_name: "MEDICAL EXAMINATION",
        code_type: "Service",
        product_price: 94,
        quantity: 213,
        discounts: 9.4,
        dispensing_fee: 0,
        instances: 213,
        minimum_price: 0.83,
        net_amount: 19918.6,
      },
      {
        hospital_id: "H0001",
        code: "IL31829",
        product_name: "IDEXX 4DX + FECAL - comprehensive",
        code_type: "Service",
        product_price: 120.44,
        quantity: 97,
        discounts: 12.04,
        dispensing_fee: 0,
        instances: 99,
        minimum_price: 1.02,
        net_amount: 11670.64,
      },
      {
        hospital_id: "H0001",
        code: "1101",
        product_name: "BORDETELLA (INJECTABLE) 6 months",
        code_type: "Service",
        product_price: 65,
        quantity: 152,
        discounts: 607.75,
        dispensing_fee: 0,
        instances: 154,
        minimum_price: 0.65,
        net_amount: 9272.25,
      },
    ],
  },
};

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export const $ = formatter.format;

// <ApolloProvider client={client}>
//   <Container maxWidth="md" style={{ paddingTop: "4em" }}>
//     <Grid container spacing={2}>
//       <Grid item xs={8}>
//         <HospitalPricingAdjuster />
//       </Grid>
//       <Grid item xs={4}>
//         <Card>
//           <CardContent>
//             <Typography variant="h5">test</Typography>
//           </CardContent>
//         </Card>
//       </Grid>
//     </Grid>
//   </Container>
// </ApolloProvider>

function PricingToolAdmin() {
  const selectedHospital = useAppSelector(
    (state: RootState) => state.pricingTool.selectedHospital
  );
  const dispatch = useAppDispatch();

  return (
    <ApolloProvider client={client}>
      <Routes>
        <Route path="" element={<PricingCampaignPage />}></Route>
        <Route
          path="campaigns/:hospitalId/:campaignId"
          element={<HospitalPriceEditor />}
        ></Route>
      </Routes>
    </ApolloProvider>
  );
}

export default PricingToolAdmin;
