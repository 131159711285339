import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ListIcon from "@mui/icons-material/List";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ForumIcon from "@mui/icons-material/Forum";
import GroupsIcon from "@mui/icons-material/Groups";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import Forum from "@mui/icons-material/Forum";
import { useAppSelector } from "../../hooks";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import HomeIcon from "@mui/icons-material/Home";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SellIcon from "@mui/icons-material/Sell";

export function MainListItems() {
  const featureFlags = useAppSelector((state) => state.engine.feature_flags);
  let navigate = useNavigate();

  return (
    <React.Fragment>
      <ListItemButton onClick={() => navigate("/")}>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>

      <ListItemButton onClick={() => navigate("/news")}>
        <ListItemIcon>
          <NewspaperIcon />
        </ListItemIcon>
        <ListItemText primary="News" />
      </ListItemButton>

      {/*<ListItemButton onClick={() => navigate("/calendar")}>
        <ListItemIcon>
          <CalendarTodayIcon />
        </ListItemIcon>
        <ListItemText primary="Calendar" />
  </ListItemButton>*/}

      {featureFlags?.includes("pricing_tool") && (
        <ListItemButton onClick={() => navigate("/pricing_tool")}>
          <ListItemIcon>
            <SellIcon />
          </ListItemIcon>
          <ListItemText primary="Impact Tool" />
        </ListItemButton>
      )}

      {/*
      <ListItemButton onClick={() => navigate("/lighthouse/code_mapper")}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="Code Mapper" />
      </ListItemButton>

      <ListItemButton onClick={() => navigate("/lighthouse/compliance_tags")}>
        <ListItemIcon>
          <ForumIcon />
        </ListItemIcon>
        <ListItemText primary="Compliance Tags" />
      </ListItemButton>

      <ListItemButton onClick={() => navigate("/powerbi/permissions")}>
        <ListItemIcon>
          <ForumIcon />
        </ListItemIcon>
        <ListItemText primary="PowerBI Permissions" />
      </ListItemButton>

      <ListItemButton onClick={() => navigate("/lighthouse/hospital_master")}>
        <ListItemIcon>
          <ForumIcon />
        </ListItemIcon>
        <ListItemText primary="Hospital Master" />
      </ListItemButton>

      <ListItemButton onClick={() => navigate("/lighthouse/employee_mapping")}>
        <ListItemIcon>
          <ForumIcon />
        </ListItemIcon>
        <ListItemText primary="Employee Mapping" />
      </ListItemButton>

      <ListItemButton onClick={() => navigate("/tasks")}>
        <ListItemIcon>
          <ForumIcon />
        </ListItemIcon>
        <ListItemText primary="Tasks" />
      </ListItemButton> */}

      {/* <ListItemButton
        onClick={() => window.open("https://community.unitedvetcare.tech")}
      >
        <ListItemIcon>
          <ForumIcon />
        </ListItemIcon>
        <ListItemText primary="Community" />
      </ListItemButton> */}

      <Divider sx={{ my: 1 }} />

      {featureFlags?.includes("dev") && (
        <ListItemButton onClick={() => navigate("/token")}>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="DEV" />
        </ListItemButton>
      )}
      {featureFlags?.includes("dev") && (
        <ListItemButton onClick={() => navigate("/injest")}>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Injest Form" />
        </ListItemButton>
      )}
    </React.Fragment>
  );
}

export function SecondaryListItems() {
  let navigate = useNavigate();

  return (
    <React.Fragment>
      {/* <ListSubheader component="div" inset>
        Saved reports
      </ListSubheader> */}
    </React.Fragment>
  );
}
