import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { MainListItems, SecondaryListItems } from "./ListItems";
import UnfoldMore from "@mui/icons-material/UnfoldMore";
import Avatar from "@mui/material/Avatar";
import { supabase } from "../../supabaseClient";
import { Routes, Route, Link, LinkProps } from "react-router-dom";
import _ from "lodash";
import { useAppDispatch } from "../../hooks";
import {
  selectHospitalMaster,
  selectHubHeader,
  setFeatureFlags,
  setHospitalMaster,
} from "../../features/engine/engineSlice";
import { useLocation } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { Session } from "@supabase/supabase-js";
import Tooltip from "@mui/material/Tooltip";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<LinkProps, "to"> & { href: LinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <Link ref={ref} to={href} {...other} />;
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const ToolbarActive = true;

const drawerWidth: number = ToolbarActive ? 220 : 0;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

const mdTheme = createTheme();

function DashboardContent(props: { children: React.ReactNode }) {
  const [open, setOpen] = React.useState(true);
  const [session, setSession] = React.useState<Session | null>(null);
  const dispatch = useAppDispatch();
  const location = useLocation();

  // get hospital master from redux selector
  const hospital_master = useSelector(selectHospitalMaster);
  const hub_header = useSelector(selectHubHeader);

  React.useEffect(() => {
    const getSupabaseSession = async () => {
      const { data } = await supabase.auth.getSession();
      setSession(data.session);
    };
    getSupabaseSession().catch(console.error);
    supabase
      .from("feature_flags")
      .select("*")
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        }
        // @ts-ignore
        dispatch(setFeatureFlags(data?.map((e) => e.feature)));
      });

    supabase
      .from("hospital_master")
      .select("*")
      .then(({ data, error }) => {
        if (error) {
          console.error(error);
        }
        dispatch(setHospitalMaster(data));
      });
  }, []);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleUserSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    // maybe handle the error if it is not null
    if (error) {
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <SnackbarProvider>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="absolute"
            open={open}
            // color="transparent"
            sx={{
              boxShadow: "inherit",
              backgroundColor: "rgb(246,245,245)",
              color: "rgba(0, 0, 0, 0.87)",
            }}
          >
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              {/* <div style={{ width: "84px" }}>
              <IconButton onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            </div> */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                // sx={{
                //   marginRight: "36px",
                //   ...(open && { display: "none" }),
                // }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h4"
                color="#ff9800"
                fontStyle="Oblique"
                fontWeight="bold"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {(location.pathname.includes("campaigns") && hub_header) ||
                  "UVC Hub"}
              </Typography>
              <IconButton color="inherit">
                <Badge badgeContent={0} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton color="inherit">
                <Badge color="secondary">
                  <Avatar>
                    {_.upperCase((session?.user?.email || [])[0])}
                  </Avatar>
                </Badge>
              </IconButton>
              <Tooltip title="SignOut">
                <IconButton onClick={handleUserSignOut}>
                  <LogoutIcon></LogoutIcon>
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>

          {ToolbarActive && (
            <Drawer variant="permanent" open={open}>
              {/* logo here */}
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  px: [1],
                }}
              >
                {open && (
                  <img
                    src={process.env.PUBLIC_URL + "/uvc_logo.png"}
                    style={{
                      height: "55px",
                      margin: "4px auto",
                    }}
                  />
                )}
              </Toolbar>
              {/* <Divider /> */}
              <List component="nav" dense={true} sx={{ paddingTop: "2em" }}>
                {/* <Box sx={{ px: 2, paddingTop: "1em" }}>
                <Box
                  sx={{
                    alignItems: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                    px: 3,
                    py: "11px",
                    borderRadius: 1,
                  }}
                >
                  <div>
                    <Typography color="inherit" variant="subtitle1">
                      Support Center
                    </Typography>
                    <Typography color="neutral.400" variant="body2">
                      SC0001
                    </Typography>
                  </div>
                  <UnfoldMore
                    sx={{
                      color: "neutral.500",
                      width: 14,
                      height: 14,
                    }}
                  />
                </Box>
              </Box> */}

                {/* <Divider sx={{ my: 1, paddingTop: "1em", color: "white" }} /> */}

                {<MainListItems />}
                {/* {<SecondaryListItems />} */}
              </List>
            </Drawer>
          )}

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}> */}
            {props.children}
            {/* </Container> */}
          </Box>
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default DashboardContent;
