import { createClient } from "@supabase/supabase-js";
import { paths } from './types/supabase'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || "";
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY || "";

export const supabase = createClient<paths>(supabaseUrl, supabaseAnonKey);

//@ts-ignore
window.supabase = supabase;
