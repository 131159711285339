import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Container,
  Modal,
  Typography,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import { useState } from "react";

const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //   ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  verticalAlign: "center",
  color: theme.palette.text.secondary,
  height: "128px",
}));


const coupaPageLinks = [

  {
    link: "https://unitedveterinarycare.coupahost.com/",
    subject: "Coupa - Support Center",
    body: "Review and approve PO's / Non-PO Backed Invoices",
  },
  {
    link: "https://unitedveterinarycare.coupahost.com/",
    subject: "Coupa - Hospital's",
    body: "Invoice Viewing: All invoices after 05/18/2023",
  },

  // {
  //   message: "Coming Soon!",
  //   subject: "Coupa - Hospital's",
  //   body: "Coming Soon in June 2023! Under Construction",
  // },

  {
    link: "http://app.stampli.com/clean.html",
    subject: "Stampli",
    body: "Invoice Viewing: 05/17/2023 and prior For May 2023 invoices remember to check both platforms! ",
  },
];



interface Link {
  subject: string;
    body: string;
    link?: string;
    message? :string;
} 
interface Props {
  coupaPageLinks: Link[];
}


const BasicCard = ({ coupaPageLinks }: Props) => {
  const [showModal, setShowModal] = useState(false);


  const HandleLinkClick = (link: Link) => {
    if (link.link) {
      window.open(link.link, "_blank");
    } else if (link.message) {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  }
  
  return (
    <>
    <Card
      sx={{ height: "148px" }}
      elevation={2}>{coupaPageLinks.map((link, index) => (
      <CardActionArea key={index} onClick={() => HandleLinkClick(link)}>
        <CardContent>
          <><Typography variant="h5" component="div">
            {link.subject} ➔
           </Typography>
           <Typography variant="body2" paddingTop="1em">
            {link.body}
          </Typography></>
        </CardContent>
        {/* { <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> } */}
      </CardActionArea>))}
    </Card>

    <Modal open={showModal} onClose={closeModal}>
      <div style={{ margin: 'auto', marginTop: '20%', width: 200, background: '#fff', padding: 16 }}>
        <Typography variant="body1">Coming Soon!</Typography>
        <Button variant="contained" color="primary" onClick={closeModal}>
          Close
        </Button>
      </div>
    </Modal>
    </>
    
  );}
    

  export default function CoupaPage() {
    return (
      <Container maxWidth="md" style={{ paddingTop: "2em" }}>
        <Grid container spacing={2} maxWidth="lg">
          {coupaPageLinks.map((link) => (
            <Grid item lg={4} md={4} sm={12} xs={12} >
              <BasicCard coupaPageLinks={[link]} />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }

