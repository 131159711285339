const exclusions = [
  {
    "uvc_subcategory_id": 311,
    "uvc_subcategory": "Other Acidifying Agent"
  },
  {
    "uvc_subcategory_id": 421,
    "uvc_subcategory": "Other Antibiotic"
  },
  {
    "uvc_subcategory_id": 436,
    "uvc_subcategory": "Other Anticonvulsant"
  },
  {
    "uvc_subcategory_id": 461,
    "uvc_subcategory": "Other Antidote"
  },
  {
    "uvc_subcategory_id": 469,
    "uvc_subcategory": "Other Antiemetic"
  },
  {
    "uvc_subcategory_id": 487,
    "uvc_subcategory": "Other Antifungal"
  },
  {
    "uvc_subcategory_id": 510,
    "uvc_subcategory": "Other Antihistamine"
  },
  {
    "uvc_subcategory_id": 529,
    "uvc_subcategory": "Other Antihypertensive"
  },
  {
    "uvc_subcategory_id": 566,
    "uvc_subcategory": "Other Antineoplastic"
  },
  {
    "uvc_subcategory_id": 634,
    "uvc_subcategory": "Other Antitussive"
  },
  {
    "uvc_subcategory_id": 641,
    "uvc_subcategory": "Other Antiviral"
  },
  {
    "uvc_subcategory_id": 726,
    "uvc_subcategory": "Other Derm"
  },
  {
    "uvc_subcategory_id": 787,
    "uvc_subcategory": "Other Derm Medicated Shampoo"
  },
  {
    "uvc_subcategory_id": 803,
    "uvc_subcategory": "Other Enzyme"
  },
  {
    "uvc_subcategory_id": 823,
    "uvc_subcategory": "Other Gastrointestinal-Appetite Stimulant"
  },
  {
    "uvc_subcategory_id": 863,
    "uvc_subcategory": "Other Gastrointesinal"
  },
  {
    "uvc_subcategory_id": 933,
    "uvc_subcategory": "Other Immunosuppressive"
  },
  {
    "uvc_subcategory_id": 970,
    "uvc_subcategory": "Other Neurological"
  },
  {
    "uvc_subcategory_id": 999,
    "uvc_subcategory": "Other Ophthalmic Antibiotic"
  },
  {
    "uvc_subcategory_id": 1044,
    "uvc_subcategory": "Other Ophthalmic"
  },
  {
    "uvc_subcategory_id": 1080,
    "uvc_subcategory": "Other Ophthalmic Steroid"
  },
  {
    "uvc_subcategory_id": 1097,
    "uvc_subcategory": "Other Otic Antibiotic"
  },
  {
    "uvc_subcategory_id": 1177,
    "uvc_subcategory": "Other Otic Antimicrobial & corticosteroid"
  },
  {
    "uvc_subcategory_id": 1218,
    "uvc_subcategory": "Other Otic Antiparasitic"
  },
  {
    "uvc_subcategory_id": 1263,
    "uvc_subcategory": "Other Otic Cleaning & Drying "
  },
  {
    "uvc_subcategory_id": 1331,
    "uvc_subcategory": "Other Pain NSAID"
  },
  {
    "uvc_subcategory_id": 1350,
    "uvc_subcategory": "Other Pain Opiate"
  },
  {
    "uvc_subcategory_id": 1367,
    "uvc_subcategory": "Other Pain"
  },
  {
    "uvc_subcategory_id": 1395,
    "uvc_subcategory": "Other Respiratory"
  },
  {
    "uvc_subcategory_id": 1405,
    "uvc_subcategory": "Other Sedative"
  },
  {
    "uvc_subcategory_id": 1483,
    "uvc_subcategory": "Other Skin Topical RX"
  },
  {
    "uvc_subcategory_id": 1518,
    "uvc_subcategory": "Other Steroid"
  },
  {
    "uvc_subcategory_id": 1574,
    "uvc_subcategory": "Other Vitamin/Minerals"
  },
  {
    "uvc_subcategory_id": 2213,
    "uvc_subcategory": "Other FDA Prescription Meds"
  },
  {
    "uvc_subcategory_id": 1848,
    "uvc_subcategory": "Cytopoint Inj"
  },
  {
    "uvc_subcategory_id": 308,
    "uvc_subcategory": "Proheart"
  },
  {
    "uvc_subcategory_id": 309,
    "uvc_subcategory": "Proheart 12"
  },
  {
    "uvc_subcategory_id": 1624,
    "uvc_subcategory": "Convenia Inj"
  },
  {
    "uvc_subcategory_id": 2370,
    "uvc_subcategory": "Solensia"
  },
  {
    "uvc_subcategory_id": 1714,
    "uvc_subcategory": "Cerenia Inj"
  },
  {
    "uvc_subcategory_id": 1671,
    "uvc_subcategory": "Other Antibiotic Inj"
  },
  {
    "uvc_subcategory_id": 1905 ,
    "uvc_subcategory": "Other Opioid Pain Inj"
  },
  {
    "uvc_subcategory_id": 1800,
    "uvc_subcategory": "Other Gastrointestinal Inj"
  },
  {
    "uvc_subcategory_id": 1777,
    "uvc_subcategory": "Other Antiparasitic Inj"
  },
  {
    "uvc_subcategory_id": 1837,
    "uvc_subcategory": "Other Hormones Inj"
  },
  {
    "uvc_subcategory_id": 1628,
    "uvc_subcategory": "Other Antibiotic Inj"
  },
  {
    "uvc_subcategory_id": 1958,
    "uvc_subcategory": "Other Sedative Inj"
  },
  {
    "uvc_subcategory_id": 1969,
    "uvc_subcategory": "Other Steriod Inj"
  },
  {
    "uvc_subcategory_id": 1934,
    "uvc_subcategory": "Other PSGAG Inj"
  },
  {
    "uvc_subcategory_id": 1709,
    "uvc_subcategory": "Other Antidote Inj"
  },
  {
    "uvc_subcategory_id": 1610,
    "uvc_subcategory": "Other Antiarrhythmic Inj"
  },
  {
    "uvc_subcategory_id": 1783,
    "uvc_subcategory": "Other Derm Inj"
  },
  {
    "uvc_subcategory_id": 1726,
    "uvc_subcategory": "Other Antihistamine Inj"
  },
  {
    "uvc_subcategory_id": 1756,
    "uvc_subcategory": "Other Antineoplastic Inj"
  },
  {
    "uvc_subcategory_id": 2003,
    "uvc_subcategory": "Other Vitamin/Minerals Inj"
  },
  {
    "uvc_subcategory_id": 1985,
    "uvc_subcategory": "Other Stimulant Inj"
  },
  {
    "uvc_subcategory_id": 1988,
    "uvc_subcategory": "Other Tranquilizer Inj"
  },
  {
    "uvc_subcategory_id": 1922,
    "uvc_subcategory": "Other Pain Inj"
  },
  {
    "uvc_subcategory_id": 1943,
    "uvc_subcategory": "Other Respiratory Inj"
  },
  {
    "uvc_subcategory_id": 1976,
    "uvc_subcategory": "Other Steriod Inj"
  },
  {
    "uvc_subcategory_id": 1863,
    "uvc_subcategory": "Other Immunotherapy Inj"
  },
  {
    "uvc_subcategory_id": 1866 ,
    "uvc_subcategory": "Other Immunotherapy Inj"
  },
  {
    "uvc_subcategory_id": 1881 ,
    "uvc_subcategory": "Other Anti-inflammatory Inj"
  },
  {
    "uvc_subcategory_id": 1886 ,
    "uvc_subcategory": "Other NSAID Inj"
  },
  {
    "uvc_subcategory_id": 1847 ,
    "uvc_subcategory": "Other Immunosuppressive Inj"
  },
  {
    "uvc_subcategory_id": 1781,
    "uvc_subcategory": "Other Antiviral Inj"
  },
  {
    "uvc_subcategory_id": 1790,
    "uvc_subcategory": "Other Enzyme Inj"
  },
  {
    "uvc_subcategory_id": 1712,
    "uvc_subcategory": "Other Anti-nausea Inj"
  },
  {
    "uvc_subcategory_id": 1694,
    "uvc_subcategory": "Other Anticoagulant Inj"
  },
  {
    "uvc_subcategory_id": 1717,
    "uvc_subcategory": "Other Antiemetic Inj"
  },
  {
    "uvc_subcategory_id": 1699 ,
    "uvc_subcategory": "Other Anticonvulsant Inj"
  },
  {
    "uvc_subcategory_id": 1691,
    "uvc_subcategory": "Other Anticholinergic Inj"
  },
  {
    "uvc_subcategory_id": 1601 ,
    "uvc_subcategory": "Other Antagonist Inj"
  },
  {
    "uvc_subcategory_id": 1736,
    "uvc_subcategory": "Other Antihypertensive Inj"
  },
  {
    "uvc_subcategory_id": 1924,
    "uvc_subcategory": "Pain Management Inj"
  },

]

export default exclusions