import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'

// Define a type for the slice state
interface EngineState {
  feature_flags: string[],
  hub_header: string,
  hospital_master: [],
}

// Define the initial state using that type
const initialState: EngineState = {
  feature_flags: [],
  hub_header: "UVC Hub",
  hospital_master: [],
}

export const engineSlice = createSlice({
  name: 'engine',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setFeatureFlags: (state, action: PayloadAction<string[]>) => {
      state.feature_flags = action.payload
    },
    setHubHeader: (state, action: PayloadAction<string>) => {
      state.hub_header = action.payload
    },
    setHospitalMaster: (state, action: PayloadAction<any>) => {
      state.hospital_master = action.payload
    }
  },
})

export const { setFeatureFlags, setHubHeader, setHospitalMaster } = engineSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.engine.feature_flags
export const selectHospitalMaster = (state: RootState) => state.engine.hospital_master
export const selectHubHeader = (state: RootState) => state.engine.hub_header

export default engineSlice.reducer