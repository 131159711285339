import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { supabase } from "../../supabaseClient";

function Post({ post_id }: { post_id: number }) {
  const [post, setPost] = useState({});

  useEffect(() => {
    supabase.functions
      .invoke("discourse_get_post", { body: JSON.stringify({ post_id }) })
      .then((res) => {
        setPost(res.data?.post_stream);
      });
  }, []);

  return (
    <Grid item xs={12} lg={12}>
      <Card sx={{ maxWidth: "800px", padding: "0px" }}>
        <CardContent>
          <div
            // @ts-ignore
            dangerouslySetInnerHTML={{ __html: post?.posts?.[0]?.cooked }}
          ></div>
        </CardContent>
      </Card>
    </Grid>
  );
}

function PostAlternate({ post }: any) {
  return (
    <Grid item xs={12} lg={12}>
      <Card sx={{ maxWidth: "600px", padding: "0px" }}>
        <CardActionArea
          href={`https://community.unitedvetcare.tech/t/${post.id}`}
          target="_blank"
        >
          <CardHeader title={post.title}></CardHeader>
          {post?.image_url && (
            <CardMedia component="img" alt="post" image={post?.image_url} />
          )}
          <CardContent>{post?.excerpt}</CardContent>
          <CardActions>
            <Button
              size="large"
              color="primary"
              href={`https://community.unitedvetcare.tech/t/${post.id}`}
              target="_blank"
            >
              Read more!
            </Button>
          </CardActions>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

function NewsContainer() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    supabase.functions
      .invoke("discourse_list_posts")
      .then((res) => {
        setPosts(res.data?.topic_list?.topics);
      })
      .catch((err) => console.error(err));
  }, []);

  if (posts.length === 0) {
    return <p>Loading...</p>;
  }

  return (
    // <Container sx={{ marginTop: "2em" }}>
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      sx={{ minHeight: "100vh", paddingBottom: "4em" }}
    >
      {posts.map((post) => (
        // @ts-ignore
        <PostAlternate post={post} />
      ))}
    </Grid>
    // </Container>
  );
}

export function NewsFeedHome() {
  return <NewsContainer />;
}
