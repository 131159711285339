import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import { definitions } from '../../types/supabase'
import { TuneRounded } from '@mui/icons-material'

// Define a type for the slice state
interface PricingToolState {
  value: number
  target_dollars: number
  selectedHospital: string | null
  priceImpact: number
  dispensingFeeImpact: number
  minimumPriceImpact: number

  minimumPrice: number
  minimumDispensingFee: number
  minimumInjectionFee: number
  dispensingFeeIncrease: number
  minimumHandlingFee: number
  minimumInjectionPrice: number
  injectionFeeIncrease: number
  tunedPrices: { [code: string]: number }
  campaignData: { [code: string]: {original_price: number, quantity: number, instances: number, list_price: number}}
  hospital_short_name: string

}

// Define the initial state using that type
export const initialState: PricingToolState = {
  value: 0,
  target_dollars: 0,
  selectedHospital: null,
  priceImpact: 0,
  dispensingFeeImpact: 0,
  minimumPriceImpact: 0,
  minimumPrice: 0,
  minimumDispensingFee: 0,
  minimumInjectionFee: 0,
  dispensingFeeIncrease: 0,
  minimumHandlingFee: 0,
  minimumInjectionPrice: 0,
  injectionFeeIncrease: 0,
  tunedPrices: {},
  campaignData: {},
  hospital_short_name: "",

}

export const pricingToolSlice = createSlice({
  name: 'pricingTool',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload
    },
    selectHospital: (state, action: PayloadAction<string>) => {
      state.selectedHospital = action.payload
    },
    setMinimumPriceSlider: (state, action: PayloadAction<number>) => {
      state.minimumPrice = action.payload
    },
    setMinimumDispensingFee: (state, action: PayloadAction<number>) => {
      state.minimumDispensingFee = action.payload
    },
    setDispensingFeeIncrease: (state, action: PayloadAction<number>) => {
      state.dispensingFeeIncrease = action.payload
    },
    setMinimumInjectionFee: (state, action: PayloadAction<number>) => {
      state.minimumInjectionFee = action.payload
    },
    setMinimumHandlingFee: (state, action: PayloadAction<number>) => {
      state.minimumHandlingFee = action.payload
    },
    setMinimumInjectionPrice: (state, action: PayloadAction<number>) => {
      state.minimumInjectionPrice = action.payload
    },
    setInjectionFeeIncrease: (state, action: PayloadAction<number>) => {
      state.injectionFeeIncrease = action.payload
    },
    hydrateCampaign: (state, action: PayloadAction<definitions["pricing_campagisn"]>) => {
      const { target_dollars, price_impact, dispensing_fee_impact, min_price_impact, current_impact ,min_min_price, min_dispensing_fee, min_handling_fee, min_injection_fee, dispensing_fee_increase, min_injection_price, injection_fee_increase, target_margin, adjusted_margin } = action.payload
      // @ts-ignore
      state.hospital_short_name = action.payload.hospital_master.short_name
      state.target_dollars = target_dollars || 0
      state.priceImpact = price_impact || 0
      state.dispensingFeeImpact = dispensing_fee_impact || 0
      state.minimumPriceImpact = min_price_impact || 0

      state.minimumDispensingFee = min_dispensing_fee || 0
      state.minimumPrice = min_min_price || 0
      state.minimumHandlingFee = min_handling_fee || 0
      state.minimumInjectionFee = min_injection_fee || 0
      state.dispensingFeeIncrease = dispensing_fee_increase || 0
      state.minimumInjectionPrice = min_injection_price || 0
      state.injectionFeeIncrease = injection_fee_increase || 0
    },
    hydrateTunedPrices: (state, action: PayloadAction<{ [code: string]: number }>) => {
      state.tunedPrices = action.payload
    },
    tunePrice: (state, action: PayloadAction<{ code: string, tuned_price: number }>) => {
      state.tunedPrices[action.payload.code] = action.payload.tuned_price
    },
    hydrateCampaignData: (state, action: PayloadAction<{ [code: string]: {code: string ,original_price: number, quantity: number, instances: number, list_price: number} }>) => {
      state.campaignData = action.payload
    },



  },
})

export const {
  increment,
  decrement,
  incrementByAmount,
  selectHospital,
  setMinimumPriceSlider,
  setMinimumDispensingFee,
  setMinimumInjectionFee,
  setDispensingFeeIncrease,
  setMinimumHandlingFee,
  setMinimumInjectionPrice,
  setInjectionFeeIncrease,
  hydrateCampaign,
  hydrateTunedPrices,
  hydrateCampaignData,
  tunePrice,


} = pricingToolSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.pricingTool.value



export default pricingToolSlice.reducer