import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";
import Auth from "./Auth";
import { supabase } from "./supabaseClient";
import { Session } from "@supabase/supabase-js";
import { Routes, Route, Link, LinkProps } from "react-router-dom";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import Homepage from "./components/homepage/Homepage";
import { store } from "./store";
import { Provider } from "react-redux";
import PricingToolAdmin from "./pages/pricing_tool/PricingToolAdmin";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useAppDispatch } from "./hooks";
import { setFeatureFlags } from "./features/engine/engineSlice";
import { NewsFeedHome } from "./pages/newsfeed/NewsFeedHome";
import { EventCalendar } from "./pages/event_calendar/EventCalendar";
import mixpanel from "mixpanel-browser";
import LogRocket from "logrocket";
import { InjestForm } from "./components/injest/InjestForm";
import CoupaPage from "./pages/coupa_tile/CoupaPage";
import LearningPage from "./pages/uvc_learning_tile/UvcLearningPage";
import { LearnCalendar } from "./pages/learn_dev_tile/learning_calendar";

const TEST = process.env.REACT_APP_PUBLIC_URL;

mixpanel.init("4a6db62d549b407463efaa8b8dbabd59", {
  debug: true,
  ignore_dnt: true,
});

LogRocket.init("aetcrl/atom");

const links = {
  compliance_tags:
    "https://jasper.unitedvetcare.tech/applications/6254910b98d3887baa457cb3/pages/6254910b98d3887baa457cb7",
  code_mapper:
    "https://jasper.unitedvetcare.tech/applications/6228f384c019e07d319ed5ed/pages/6228f384c019e07d319ed5f0",
  hospital_master:
    "https://jasper.unitedvetcare.tech/applications/61ccfbb67b770971554a7250/pages/61ef0236e531791eaeb9de70",
  powerbi_permissions:
    "https://jasper.unitedvetcare.tech/applications/61e6fcf2e531791eaeb9de25/pages/61e6fcf2e531791eaeb9de27",
  employee_mapping:
    "https://atom.unitedvetcare.tech/app/employee-mapper/employee-mapping-tool-6266bcbc0a5f4668eb29c421",
};

function Embed({ url }: { url: string }) {
  const getSupabaseSession = async () => {
    const { data } = await supabase.auth.getSession();
    const token = data.session?.access_token;
    return token;
  };
  const token = getSupabaseSession().catch(console.error);
  return (
    <div>
      test
      <iframe
        style={{ width: "100%", border: "none", height: "calc(100vh - 64px)" }}
        src={`${url}?embed=true&uvcjwt=${token}`}
        title="appsmith"
      />
    </div>
  );
}

function EmbedBudi() {
  return (
    <iframe
      style={{ width: "100%", border: "none", height: "calc(100vh - 64px)" }}
      src={`https://apps.powerapps.com/play/8e71f466-5fc3-488f-bd78-503c56f9b2f5?source=iframe`}
      title="powerapps2"
    />
  );
}

function EmbedPowerBI() {
  return (
    <iframe
      title="UVC - Consolidated Dashboard - Practice Overview/Trend"
      style={{ width: "100%", border: "none", height: "calc(100vh - 64px)" }}
      src="https://app.powerbi.com/reportEmbed?reportId=75f3dde0-4408-4a8d-b42b-d63deee3fa32&appId=ca32cfc7-919a-4973-a51c-48b6be65c38f&autoAuth=true&ctid=ce76298f-68d5-4b4c-8ace-1f905bdd4538&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWNlbnRyYWwtYS1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9"
      frameBorder="0"
      // allowFullScreen="true"
    ></iframe>
  );
}

function EmbedCommunity() {
  return (
    <iframe
      title="UVC Community"
      style={{ width: "100%", border: "none", height: "calc(100vh - 64px)" }}
      src="https://community.unitedvetcare.tech/login"
      frameBorder="0"
      // allowFullScreen="true"
    ></iframe>
  );
}

function App() {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    const getSupabaseSession = async () => {
      const { data } = await supabase.auth.getSession();
      const email = data?.session?.user.email;

      if (email) {
        mixpanel.identify(email);
        mixpanel.people.set({ email });
        LogRocket.identify(email || "");
      }

      setSession(data.session);
    };

    getSupabaseSession().catch(console.error);

    supabase.auth.onAuthStateChange((_event, session) => {
      const email = session?.user.email;

      if (email) {
        mixpanel.identify(email);
        mixpanel.people.set({ email });
        LogRocket.identify(email || "");
      }

      setSession(session);
    });
  }, []);

  useEffect(() => {
    mixpanel.track("home", { page: "/" });
    mixpanel.track_links("#trackable_link", "link");
  }, []);

  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="App">
          {!session && <Auth />}
          {session && (
            <DashboardLayout>
              <Routes>
                <Route path="/" element={<Homepage />}></Route>
                <Route path="/news" element={<NewsFeedHome />}></Route>
                <Route path="/calendar" element={<EventCalendar />}></Route>
                <Route
                  path="/lighthouse/code_mapper"
                  element={<Embed url={links.code_mapper} />}
                ></Route>
                <Route
                  path="/testWorkbook"
                  element={
                    <iframe
                      title="test"
                      style={{
                        width: "100%",
                        border: "none",
                        height: "calc(100vh - 64px)",
                      }}
                      scrolling="no"
                      src="https://unitedvetcare.sharepoint.com/sites/uvc_gp_hospital_file_share/_layouts/15/Doc.aspx?sourcedoc={2dea8986-a274-4967-9087-c992fb8c1807}&action=embedview&wdAllowInteractivity=False&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True&edesNext=false&resen=false"
                    ></iframe>
                  }
                ></Route>
                <Route
                  path="/lighthouse/compliance_tags"
                  element={<Embed url={links.compliance_tags} />}
                ></Route>
                <Route
                  path="/powerbi/permissions"
                  element={<Embed url={links.powerbi_permissions} />}
                ></Route>
                <Route
                  path="/lighthouse/hospital_master"
                  element={<Embed url={links.hospital_master} />}
                ></Route>
                <Route
                  path="/lighthouse/employee_mapping"
                  element={<Embed url={links.employee_mapping} />}
                ></Route>
                <Route path="/tasks" element={<EmbedBudi />}></Route>
                <Route path="/reports" element={<EmbedPowerBI />}></Route>
                <Route
                  path="/pricing_tool/*"
                  element={<PricingToolAdmin />}
                ></Route>
                <Route
                  path="/token"
                  element={
                    <div
                      style={{
                        padding: "4em",
                        paddingRight: "4em",
                        inlineSize: "400px",
                      }}
                    >
                      {session.access_token}
                      <br />
                      {TEST}
                      <br />
                      {process.env.VERCEL_GIT_COMMIT_SHA}
                    </div>
                  }
                ></Route>
                <Route path="/injest" element={<InjestForm />}></Route>
                <Route path="/community" element={<EmbedCommunity />}></Route>
                <Route path="/coupa" element={<CoupaPage />}></Route>
                <Route path="/learning" element={<LearningPage />}></Route>
                <Route path="/virtuallearning" element={<LearnCalendar />}></Route>
              </Routes>
            </DashboardLayout>
          )}
        </div>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
