import React from "react";
//import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  "e6ec7d76a42d6c312f324cf28b15040aTz01MzcwNSxFPTE2OTkxNDY0NDE2NjUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
  //,
  //document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
