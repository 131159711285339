import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { supabase } from "../../supabaseClient";
import { definitions } from "../../types/supabase";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import InputAdornment from '@mui/material/InputAdornment';
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";



const QueryPricingWithUsage = gql`
  query GetVwPricingUsagePriceChange($hospital_id: String!) {
    evo_pricing_pricing_with_usage_hospital_group(
      where: { hospital_id: { _eq: $hospital_id }, _and: {product_price: {_gt: "0"}}}
      order_by: { net_amount: desc }
    ) {
      hospital_id
      code
      pms_code
      product_name
      code_type
      uvc_subclass
      uvc_subcategory
      uvc_subcategory_id
      product_price
      quantity
      discounts
      dispensing_fee
      instances
      minimum_price
      net_amount
      list_price

    }
  }
`;

function CampaignSelectBox({ hospital_id }: { hospital_id: string })  {
  const [loadingPage, setLoadingPage] = useState(true);
  const [day, setDay] = useState<Date | null>(null);
  const [campaignName, setCampaignName] = useState("");
  const [targetDollars, setTargetDollars] = useState(0);
  const [targetMargin, setTargetMargin] = useState(100);

  const navigate = useNavigate();
  const [pageLoadData, setPageData] = useState<{[evo_pricing_pricing_with_usage_hospital_group: string]: {code: string, hospital_id: string,original_price: number, quantity: number, instances: number, list_price: number, product_price: number, dispensing_fee: number, minimum_price: number} }>({});
  const [campaigns, setCampaigns] = useState<
    definitions["pricing_campagisn"][]
  >([]);

  useEffect(() => {
    getCampaigns();
  }, [hospital_id]);





const { data, error } = useQuery(QueryPricingWithUsage, {
      variables: { hospital_id: hospital_id },
    });console.log("new1",data);



    useEffect(() => {
      // This code block will run when the component mounts

      if (data) {
        setPageData(data?.evo_pricing_pricing_with_usage_hospital_group);
      }

      else{
        console.error(error);
      }
    }, [data]); // Empty dependency array ensures this effect runs once when the component mounts


    async function createCampaign()  {
      if (day && campaignName) {
          const { data: campaignData, error: campaignError } = await supabase
            .from("pricing_campagisn")
            .insert({
              hospital_id,
              title: campaignName,
              effective_date: day.toISOString(),
              target_dollars: targetDollars,
              target_margin: targetMargin / 100,
            })
            .select("*");

          if (campaignError) {
            console.error(campaignError);
            return;
          }

          await campaignData;

          const newCampaignId = campaignData?.[0]?.id;


          const hasura_data_kays = Object.keys(pageLoadData).filter(
            (evo_pricing_pricing_with_usage_hospital_group) => pageLoadData[evo_pricing_pricing_with_usage_hospital_group]
          );;(console.log("key", hasura_data_kays))

          const pageLoad = hasura_data_kays.map((evo_pricing_pricing_with_usage_hospital_group) =>({
            hospital_id: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].hospital_id,
            campaign_id: newCampaignId,
            code: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].code,
            original_price: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].product_price,
            tuned_price:pageLoadData[evo_pricing_pricing_with_usage_hospital_group].product_price,
            quantity: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].quantity,
            instances: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].instances,
            original_min_price: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].minimum_price,
            original_dispensing_fee: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].dispensing_fee,
            list_price: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].list_price,

          }));(console.log("pagenew", pageLoad))

          const { error: error3 } = await supabase
            .from("pricing_tuned_prices")
            .insert(pageLoad)
            .select();
            if (error) {
              console.error(error);
            }
            if (campaignData) {
              getCampaigns();
            }
        }};

    async function getCampaigns() {
      try {
        const { data, error, status } = await supabase
          .from("pricing_campagisn")
          .select("*")
          .eq("hospital_id", hospital_id);

        if (error && status !== 406) {
          throw error;
        }

        if (data) {
          setCampaigns(data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingPage(false);
      }
    }




  return (
    <>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            // width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        >
          <nav aria-label="main mailbox folders">
            <List>
              {campaigns.map((campaign) => (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() =>
                      navigate(
                        `/pricing_tool/campaigns/${campaign.hospital_id}/${campaign.id}`
                      )
                    }
                  >
                    <ListItemText primary={campaign.title} />
                    <ListItemText primary={campaign.effective_date} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </nav>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack sx={{ marginTop: "1em" }} spacing={2}>
          <TextField
            id="outlined-basic"
            label="Campaign Name"
            variant="outlined"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
          />
          <DatePicker
            label="Campaign Effective Date"
            value={day}
            onChange={(newValue) => {
              setDay(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <TextField
            id="outlined-basic"
            label="Target Dollar Growth"
            variant="outlined"
            type="number"
            value={targetDollars.toString()}
            InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}
            onChange={(e) => {
              if (e.target.value === "") {
                setTargetDollars(0);
                return;
              }
              setTargetDollars(Number(e.target.value))}}
          />
          <TextField
            id="outlined-basic"
            label="Outside Lab Markup Target"
            variant="outlined"
            type="number"
            value={targetMargin.toString()}
            InputProps={{startAdornment: <InputAdornment position="start">%</InputAdornment>}}
            onChange={(value) => {
              if (value.target.value === "") {
                setTargetMargin(0);
                return;
              }
              setTargetMargin(parseFloat(value.target.value));
            }}

            inputProps={{
              maxLength: 4,
              step: "1.0",
              style: {
                fontSize: "1em",
              },
            }}
          />

          <Button variant="contained" onClick={createCampaign}>
            Create Campaign
          </Button>
        </Stack>
      </Grid>
    </>
  );
};

const PricingCampaignPage = () => {
  const [loading, setLoading] = useState(true);
  const [activeHospital, setActiveHospital] = useState<string | null>(null);
  const [hospitalList, setHospitalList] = useState<
    definitions["hospital_master"][]
  >([]);

  useEffect(() => {
    getHospitalList();
  }, []);

  const getHospitalList = async () => {
    try {
      setLoading(true);
      const { data, error, status } = await supabase
        .from("hospital_master")
        .select("id, short_name");

      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        setHospitalList(data);
      }
    } catch (error) {
      // @ts-ignore
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={hospitalList.map((e) => ({
              value: e.id,
              // label: e.short_name + " - " + e.id,
              label: e.id ===
              'EvansEast_DenverCat' || e.id === 'AcDowntown_AcEast' || e.id === 'Calhoun' ? e.short_name : `${e.short_name} - ${e.id}`,
            }))}
            onChange={(e, value) =>
              value ? setActiveHospital(value.value) : setActiveHospital(null)
            }
            sx={{ width: 360, marginTop: "1em" }}
            renderInput={(params) => <TextField {...params} label="Hospital" />}
          />
        </Grid>
        {activeHospital && <CampaignSelectBox hospital_id={activeHospital} />}
      </Grid>
    </Container>
  );
};

export default PricingCampaignPage;
