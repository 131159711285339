import React, { SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { $ } from "./PricingToolAdmin";
import * as _ from "lodash";
import { gql, useQuery } from "@apollo/client";
import { supabase } from "../../supabaseClient";
import { definitions } from "../../types/supabase";
import { useParams } from "react-router-dom";
import "./HospitalPriceEditor.css";
import {
  DataGridPro,
  GridRenderCellParams,
  GridFilterModel,
  GridLinkOperator,
  useGridApiContext,
  useGridSelector,
  gridColumnFieldsSelector,
  GridFilterInputValue,
} from "@mui/x-data-grid-pro";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import {
  hydrateCampaign,
  hydrateTunedPrices,
  selectHospital,
  setDispensingFeeIncrease,
  setMinimumDispensingFee,
  setMinimumHandlingFee,
  setMinimumInjectionFee,
  setMinimumInjectionPrice,
  setInjectionFeeIncrease,
  setMinimumPriceSlider,
  tunePrice,
  hydrateCampaignData,
} from "../../features/pricingTool/pricingToolSlice";
import Papa from "papaparse";

import { useSnackbar } from "notistack";
import ClearIcon from "@mui/icons-material/Clear";
import {
  selectHospitalMaster,
  selectHubHeader,
  setHubHeader,
} from "../../features/engine/engineSlice";
import shadows from "@mui/material/styles/shadows";
import { clockNumberClasses } from "@mui/x-date-pickers";
import { CalculateSupaFields, fees, ICodeImpactFull, ITuned, Supa} from "./pricing_calc";



//after adding new table from hasura, you must adjust permissions in hasura table dashboard
//might need to add back in and exclude products with no value "where: { hospital_id: { _eq: $hospitalId }, _and: {product_price: {_gt: "0"}}}"
const QueryPricingWithUsage = gql`
  query GetVwPricingUsagePriceChange($hospitalId: String!) {
    evo_pricing_pricing_with_usage_hospital_group(
      where: { hospital_id: { _eq: $hospitalId }})
      {
      hospital_id
      code
      product_name
      net_amount
      code_type
      uvc_subclass
      uvc_subcategory
      uvc_subcategory_id
      pms_code
    }
  }
`;


function HospitalPriceEditor() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSubclass, setFilterSubclass] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { campaignId, hospitalId } = useParams();
  const [increaseCatPerc, setIncreaseCatPerc] = useState(0.0);
  console.log(increaseCatPerc);
  const [tunedPrices2, setValues] = useState<{ [code: string]: number }>({});
  const [hasChanges, setHasChanges] = useState(false);


  const tunedPrices = useAppSelector(
    (state: RootState) => state.pricingTool.tunedPrices
  );




  const [supLoading, setLoading] = useState(true);
  const [priceLoading, setPriceLoading] = useState(true);


  const [impactDatas, setImpactData] = useState<ICodeImpactFull[]>([]);
  console.log("dataimpa", impactDatas)




  const { loading, error, data } = useQuery(QueryPricingWithUsage, {
    variables: { hospitalId: hospitalId},
  });

  useEffect(() => {
    if (!loading && !error && data) {

    }setPriceLoading(false);
    ;
  }, [loading, error, data])





  const [campaign, setCampaign] = useState<definitions["pricing_campagisn"] | null>(null);
  const [campaignData, setCampaignData] = useState<{ [code: string]: {code: string, original_price: number, tuned_price: number,quantity: number, instances: number, list_price: number, original_min_price: number, original_dispensing_fee: number} }>({});
 console.log("camData",campaignData)


  const snackbar = useSnackbar();

  const dispatch = useAppDispatch();

  const minimumPrice = useAppSelector(
    (state: RootState) => state.pricingTool.minimumPrice
  );
  const minimumDispensingFee = useAppSelector(
    (state: RootState) => state.pricingTool.minimumDispensingFee
  );
  const dispensingFeeIncrease = useAppSelector(
    (state: RootState) => state.pricingTool.dispensingFeeIncrease
  );
  const minimumInjectionFee = useAppSelector(
    (state: RootState) => state.pricingTool.minimumInjectionFee
  );
  const minimumHandlingFee = useAppSelector(
    (state: RootState) => state.pricingTool.minimumHandlingFee
  );
  const minimumInjectionPrice = useAppSelector(
    (state: RootState) => state.pricingTool.minimumInjectionPrice
  );
  const injectionFeeIncrease = useAppSelector(
    (state: RootState) => state.pricingTool.injectionFeeIncrease
  );



  const hospital_master: Hospital[] = useAppSelector(selectHospitalMaster) || [];

  interface Hospital {
    id: string;
    short_name: string;
  }


    useEffect(() => {

    // set hub header to hospital name
    const hospital = hospital_master.find((h: any) => h.id === hospitalId);
    console.log('hospital:', hospital);

    if (hospital) {
    let hubHeader = `Impact Tool`;

    if (hospitalId === "AcDowntown_AcEast" || hospitalId === "EvansEast_DenverCat" || hospitalId === "Calhoun") {
      hubHeader = `${hubHeader} - ${hospital.short_name}`;
    } else {
      hubHeader = `${hubHeader} - ${hospital.short_name} - ${hospitalId}`;
    }
    dispatch(setHubHeader(hubHeader))
  } else {

  }
}, [campaignId, hospital_master, hospitalId]);


    const calculateImpactData = (data: any, campaignData: any, minimumDispensingFee: any, minimumInjectionFee: any, dispensingFeeIncrease: any, minimumPrice: any, minimumHandlingFee: any, minimumInjectionPrice: any, injectionFeeIncrease: any, tunedPrices: any) => {
      const impact_data: ICodeImpactFull[] = data?.evo_pricing_pricing_with_usage_hospital_group.map((code: Supa) => {
        if (code) {

        const campaignDataForCode = campaignData?.[code.code];
        if (campaignDataForCode) {
        const mergedData = {
          code: campaignDataForCode.code,
          quantity: campaignDataForCode.quantity,
          instances: campaignDataForCode.instances,
          list_price: campaignDataForCode.list_price || 0,
          original_price: campaignDataForCode.original_price,
          original_min_price: campaignDataForCode.original_min_price || 0,
          original_dispensing_fee: campaignDataForCode.original_dispensing_fee || 0,
          uvc_subclass: code.uvc_subclass || "",
          uvc_subcategory: code.uvc_subcategory || "",
          uvc_subcategory_id: code.uvc_subcategory_id || 0,
          product_name: code.product_name || "",
          net_amount: code.net_amount || 0,
          pms_code: code.pms_code,
          code_type: code.code_type || "",
        };
        const impact = CalculateSupaFields(
          mergedData,
          {
            minimumDispensingFee,
            minimumInjectionFee,
            dispensingFeeIncrease,
            minimumPrice,
            minimumHandlingFee,
            minimumInjectionPrice,
            injectionFeeIncrease,
          },
          { tuned_price: tunedPrices[code.code]},

        );
        return impact;
      }
    }
    return null;
    });
    const filteredImpactData = impact_data?.filter((data: any) => data!== null);


    setImpactData(filteredImpactData)

    }; useEffect(() => {
      // calls the function to calculate the impact data when dependencies change
      calculateImpactData(
        data,
        campaignData,
        minimumDispensingFee,
        minimumInjectionFee,
        dispensingFeeIncrease,
        minimumPrice,
        minimumHandlingFee,
        minimumInjectionPrice,
        injectionFeeIncrease,
        tunedPrices,
      );
    }, [
      data,
      campaignData,
      minimumDispensingFee,
      minimumInjectionFee,
      dispensingFeeIncrease,
      minimumPrice,
      minimumHandlingFee,
      minimumInjectionPrice,
      injectionFeeIncrease,
      tunedPrices,
    ]);





  useEffect(() => {
    getCampaign(campaignId || "");
    getTunedPrices(campaignId || "")

    if (!tunedPrices) {

    }

    const beforeUnloadHandler = () => {
      return true;
    };
    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);

    };
  }, [campaignId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;






  async function getCampaign(campaign_id: string) {
    const { data, error } = await supabase
      .from("pricing_campagisn")
      .select(
        `
        *,
        hospital_master!pricing_campagisn_hospital_id_fkey (
          short_name
        )
      `
      )
      .eq("id", campaign_id)
      .single();
    if (error) {
      console.error(error);
    }
    if (data) {
      setCampaign(data);
      dispatch(hydrateCampaign(data));

    }
  }

  async function getTunedPrices(campaign_id: string) {
    try {
    const { data : supData, error } = await supabase
      .from("pricing_tuned_prices")
      .select(`*`)
      .eq("campaign_id", campaignId);
    if (error) {
      console.error(error);
      return;
    } if (supData) {


    const newPrices =
      supData?.reduce((acc:any, e:any) => ({ ...acc, [e.code]: e.tuned_price }), {}) || {};
    setValues({ ...tunedPrices, ...newPrices });
    dispatch(hydrateTunedPrices(newPrices));


    const SupabaseData = supData?.reduce((acc: any, e: any) => {
      const { code, tuned_price, original_price, quantity, instances, list_price, original_min_price, original_dispensing_fee} = e;
      return { ...acc, [code]: {code, tuned_price, original_price, quantity, instances, list_price, original_min_price, original_dispensing_fee}};
    }, {}) || {};
    setCampaignData({ ...campaignData, ...SupabaseData});
      // dispatch(hydrateCampaignData(SupabaseData));

  } else {
    setLoading(false)
  }
} catch (error) {
  console.error("Unexpected error", error)
}}
;


//Static campaign data
  const target = campaign?.target_dollars || 0;
  const target_margin = campaign?.target_margin || 0;
  const campaignName = campaign?.title;






//campaign impacts
  const current =  (impactDatas || []).map((e) => e.current_impact).reduce(_.add, 0) ;
  const pure_price_impact = (impactDatas || []).map((e) => e.pure_price_impact).reduce(_.add, 0) ;
  const dispensing_fee_impact = (impactDatas || []).map((e) => e.dispensing_fee_impact).reduce(_.add, 0) ;
  const minimum_price_impact = (impactDatas || []).map((e) => e.minimum_price_impact).reduce(_.add, 0);



//outside lab markups
  const outside_lab_margin_tuned_rev = (impactDatas || []).map((code:any) => code.tuned_revs).reduce(_.add, 0);
  const outside_lab_margin_sum_list_cost = (impactDatas || []).map((code:any) => code.sum_list_cost).reduce(_.add, 0);
  const outside_lab_margin = !isNaN(((outside_lab_margin_tuned_rev / outside_lab_margin_sum_list_cost)-1)*(100))  ? ((outside_lab_margin_tuned_rev / outside_lab_margin_sum_list_cost)-1)*(100) : 0;




  const updatePriceSlider = (code: string, price: number) => {
    dispatch(tunePrice({ code, tuned_price: price }));
    setHasChanges(true);
  };

  const handleInputChange = (code: string, price: number) => {
    dispatch(tunePrice({ code, tuned_price: price }));
    setHasChanges(true);
  };




const current_margin_product: {[code: string]: number }= {};
  impactDatas?.map((e: any) => {
  const {current_margin_products, code} = e;
  current_margin_product[code] = current_margin_products
});


const adjusted_margin_product: {[code: string]: number }= {};
    impactDatas?.map((e: any) => {
    const {adjusted_margin_products, code} = e;
    adjusted_margin_product[code] = adjusted_margin_products
  });

  // const subclassMarkupFilter: {[code: string]: string }= {};
  //   impactDatas?.map((e: any) => {
  //   const {uvc_subclass, code} = e;
  //   subclassMarkupFilter[code] = uvc_subclass
  // });


const list_prices: {[code: string]: number }= {};
  impactDatas?.map((e: any) => {
  const {list_price, code} = e;
  list_prices[code] = list_price
});


const instance: {[code: string]: number }= {};
  impactDatas?.map((e: any) => {
  const {instances, code} = e;
  instance[code] = instances

  });


const quantities: {[code: string]: number }= {};
  impactDatas?.map((e: any) => {
   const {quantity, code} = e;
   quantities[code] = quantity
 });

const original_prices: {[code: string]: number }= {};
 impactDatas?.map((e: any) => {
  const {product_price, code} = e;
  original_prices[code] = product_price

});

const price_impacts: {[code: string]: number } = {};
  impactDatas?.map((e) => {
  const {price_impact, code} = e;
  price_impacts[code] = price_impact

});



const new_min_prices: {[code: string]: number } = {};
  impactDatas?.map((e) => {
  const {new_min_price, code} = e;
  new_min_prices[code] = new_min_price

});

const original_dispensing_fee: {[code: string]: number }= {};
  impactDatas?.map((e: any) => {
  const {dispensing_fee, code} = e;
  original_dispensing_fee[code] = dispensing_fee

});

const new_dispensing_fees: {[code: string]: number } = {};
  impactDatas?.map((e) => {
  const {new_dispensing_fee, code} = e;
  new_dispensing_fees[code] = new_dispensing_fee

});

const disp_impacts: {[code: string]: number } = {};
  impactDatas?.map((e:any) => {
  const {dispensing_fee_impact, code} = e;
  disp_impacts[code] = dispensing_fee_impact

});


const final: {[code: string]: number } = {};
 impactDatas?.map((e:any) => {
  const {current_impact, code} = e;
  final[code] = current_impact

});


const handleSliderChange = () => {
  setHasChanges(true);
}

  // const current2 = impact_data.map((e) => e.total_impact2).reduce(_.add);

  // save csv to file and download
  const saveCSV = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.click();
  };

  const handleExport = () => {
  if (isSaving && !hasChanges) {
    exportData();
  } else if (!isSaving) {
    snackbar.enqueueSnackbar("Please Save the Campaign before Exporting", { variant: "error" });
  } else {
    snackbar.enqueueSnackbar("Please Save the changes Exporting", { variant: "error" });
  }
};


  async function exportData() {
    const export_impact_data =impactDatas?.map((code: any) => ({
        campaignId: campaignId,
        hospital_id: hospitalId,
        subclass: code.uvc_subclass,
        code: code.code,
        pims_code: String(code.pms_code),
        description: code.product_name,
        old_price: code.original_price,
        new_price: _.round(code.tuned_price, 2),
        price_diff: _.round(code.tuned_price - code.original_price, 2) || 0,
        old_min_price: code.original_min_price,
        new_min_price: code.new_min_price,
        min_price_diff: code.new_min_price - code.original_min_price,
        list_prices: code.list_price,
        instances: code.instances,
        quantity: code.quantity,
        current_margin_product: _.round(code.current_margin_products/100, 2),
        adjusted_margin_product: _.round(code.adjusted_margin_products/100, 2),
        average_quantity: _.round(code.average_quantity, 2),
        net_amount: code.net_amount,
        min_dispensing_fee: code.new_min_dispensing_fee,
        old_base_price: _.round(code.base_price, 2),
        new_base_price: _.round(code.new_base_price, 2),
        // price_difference: e.price_difference,
        price_impact: _.round(code.price_impact, 2),
        old_dispensing_fee: code.original_dispensing_fee,
        new_dispensing_fee: code.new_dispensing_fee,
        disp_difference: code.dispensing_fee_difference,
        disp_impact: code.dispensing_fee_impact,
        final: _.round(code.current_impact, 2),
      }))







    const blob = new Blob([Papa.unparse(export_impact_data)], {
      type: "text/csv;charset=utf-8;",
    });
      saveCSV(blob, "pricing.csv");
  }



  async function handleSave() {
    setIsSaving(true);
    setHasChanges(false);
    const { error, data } = await supabase
      .from("pricing_campagisn")
      .update({
        min_min_price: minimumPrice,
        min_dispensing_fee: minimumDispensingFee,
        min_injection_fee: minimumInjectionFee,
        min_handling_fee: minimumHandlingFee,
        dispensing_fee_increase: dispensingFeeIncrease,
        price_impact: pure_price_impact,
        dispensing_fee_impact: dispensing_fee_impact,
        min_price_impact: minimum_price_impact,
        current_impact: current,
        min_injection_price: minimumInjectionPrice,
        injection_fee_increase: injectionFeeIncrease,
        adjusted_margin: outside_lab_margin/100,

      })
      .match({ id: campaignId });





    const changed_prices = impactDatas?.map((code:any) => ({
      hospital_id: hospitalId,
      campaign_id: campaignId,
      code: code.code,
      original_price: code.original_price,
      tuned_price: code.tuned_price,
      quantity: code.quantity,
      instances: code.instances,
      average_quantity: _.round(code.average_quantity, 2),
      price_impact: _.round(code.price_impact, 2),
      original_min_price:code.original_min_price,
      new_min_price: code.new_min_price,
      original_dispensing_fee: code.original_dispensing_fee,
      new_dispensing_fee: code.new_dispensing_fee,
      disp_impact: code.dispensing_fee_impact,
      final: _.round(code.current_impact, 2),
      list_price: code.list_price,
      current_margin_product: _.round(current_margin_product[code]/100, 2) || 0,
      adjusted_margin_product: _.round(adjusted_margin_product[code]/100, 2) || 0,


    }));


    const { error: error2 } = await supabase
      .from("pricing_tuned_prices")
      .upsert(changed_prices, {
        onConflict: "code,campaign_id,hospital_id",
      })

      .select();
      console.log("save", changed_prices)


    if (error || error2) {
      snackbar.enqueueSnackbar("Error saving campaign", { variant: "error" });
    } else {
      snackbar.enqueueSnackbar("Pricing saved", { variant: "success" });

    }

    // if (Object.keys(tunedPrices).length > 0 && Object.keys(originalPrices).length === 0) {
    //   const hasura_data_kays = Object.keys(pageLoadData).filter(
    //     (evo_pricing_pricing_with_usage_hospital_group) => pageLoadData[evo_pricing_pricing_with_usage_hospital_group]
    //   );;(console.log("key", hasura_data_kays))


    //   const pageLoad = hasura_data_kays.map((evo_pricing_pricing_with_usage_hospital_group) =>({
    //         hospital_id: hospitalId,
    //         campaign_id: campaignId,
    //         code: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].code,
    //         original_price: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].product_price,
    //         tuned_price: tunedPrices?.code || pageLoadData[evo_pricing_pricing_with_usage_hospital_group].product_price,
    //         quantity: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].quantity,
    //         instances: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].instances,
    //         original_min_price: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].minimum_price,
    //         original_dispensing_fee: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].dispensing_fee,
    //         list_price: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].list_price,
    //   }))


    // if (Object.keys(tunedPrices).length > 0 && Object.keys(originalPrices).length === 0) {

    //   const pageLoadDataMapping = Object.fromEntries(
    //     Object.entries(pageLoadData).map(([code, item]) => [item.code, item])
    //   );
    //     const hasura_data_kays = Object.keys(pageLoadData).filter(
    //       (code) => {
    //       const pageLoadItem = pageLoadDataMapping[code];
    //       const tunedPriceItem = tunedPrices;

    //       if (tunedPriceItem && pageLoadItem) {
    //         return tunedPriceItem[code] !== pageLoadItem[code];
    //       }

    //       return false;
    //     });
    //     });
    //     );;(console.log("key", hasura_data_kays))


    //     const pageLoad = hasura_data_kays.map((code) =>({
    //           hospital_id: hospitalId,
    //           campaign_id: campaignId,
    //           code: pageLoadData[code].code,
    //           original_price: pageLoadData[code].product_price,
    //           tuned_price: tunedPrices?.code || pageLoadData[code].product_price,
    //           quantity: pageLoadData[code].quantity,
    //           instances: pageLoadData[code].instances,
    //           original_min_price: pageLoadData[code].minimum_price,
    //           original_dispensing_fee: pageLoadData[code].dispensing_fee,
    //           list_price: pageLoadData[code].list_price,
    //     }))

    //  const pageLoadFiltered = Object.keys(pageLoad)
    //   .filter((code) => !tunedPrices[code]);

    //   const pageDataCombo = pageLoadFiltered
    //   .map((code) =>({
    //     hospital_id: hospitalId,
    //     campaign_id: campaignId,
    //     code: pageLoadData.code,
    //     original_price: pageLoadData[code].product_price,
    //     tuned_price: tunedPrices?.code || pageLoadData[code].product_price,
    //     quantity: pageLoadData[code].quantity,
    //     instances: pageLoadData[code].instances,
    //     original_min_price: pageLoadData[code].minimum_price,
    //     original_dispensing_fee: pageLoadData[code].dispensing_fee,
    //     list_price: pageLoadData[code].list_price,

    //   })); (console.log("any", pageLoadFiltered));(console.log("inst", tunedPrices))


    //   const { error: error3 } = await supabase
    //   .from("pricing_tuned_prices")
    //   .insert(pageDataCombo)
    //   .select(); (console.log("min", pageLoad))
    //   }



    // if (Object.keys(tunedPrices).length > 0) {
    //   const hasura_data_kays = Object.keys(pageLoadData).filter(
    //     (evo_pricing_pricing_with_usage_hospital_group) => pageLoadData[evo_pricing_pricing_with_usage_hospital_group]
    //   );;(console.log("key", hasura_data_kays))

    //   const pageLoad = hasura_data_kays.map((evo_pricing_pricing_with_usage_hospital_group) =>({
    //     hospital_id: hospitalId,
    //     campaign_id: campaignId,
    //     code: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].code,
    //     original_price: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].product_price,
    //     tuned_price: tunedPrices?.code || pageLoadData[evo_pricing_pricing_with_usage_hospital_group].product_price,
    //     quantity: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].quantity,
    //     instances: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].instances,
    //     original_min_price: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].minimum_price,
    //     original_dispensing_fee: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].dispensing_fee,
    //     list_price: pageLoadData[evo_pricing_pricing_with_usage_hospital_group].list_price,

    //   })); (console.log("any", pageLoad))


    //   const { error: error3 } = await supabase
    //   .from("pricing_tuned_prices")
    //   .upsert(pageLoad)
    //   .select(); (console.log("min", pageLoad))
    //   }

  }

  // function handleReset() {
  //   // setValues(initialPrices);
  //   dispatch(hydrateTunedPrices({}));
  // }

  const gridData = data.evo_pricing_pricing_with_usage_hospital_group.filter((e: any) => {
    const isService = e.code_type === "Service";
    const supabaseDataByCode = campaignData ? campaignData[e.code] : null;
    const isMatchingCampaignData = supabaseDataByCode?.code === e.code;


      return isService && isMatchingCampaignData




  });

  const columns = [
    { headerName: "Product Name", field: "product_name", width: 300},

    // , valueGetter: (params: GridRenderCellParams) => {

    //   const nameProduct = params.row.product_name;


    //   const productNames: {[code: string]: string } = {}
    //   ;
    //   data.evo_pricing_pricing_with_usage_hospital_group.filter((e:any) => {
    //     const { product_name, code } = e;

    //     if (tunedPrices[code]) {
    //       productNames[code] = product_name;
    //     }
    //   });
    //   // dispatch(hydrateProductNames(productNames));

    //   if (Object.keys(productNames).length > 0) {
    //     return {
    //       productNames: productNames[params.row.code] || nameProduct
    //     }
    //   } ;


    // const productSupabaseNames = data?.evo_pricing_pricing_with_usage_hospital_group?.filter((e:any) => e.code === tunedPrices.code)
    // .reduce((acc:any, e:any) => ({ ...acc, [e.code]: product_names.code.product_name}), {}) || {};
    // dispatch(hydrateProductNames(productSupabaseNames)); console.log("access", productNames)


        // const productSupabaseNames = data?.filter((e:any) => e.code === product_names[e.code].code)
    // .reduce((acc:any, e:any) => ({ ...acc, [e.code]: product_names[e.code].product_name }), {}) || {};
    // setProdNames({ ...productNames, ...productSupabaseNames});
    // dispatch(hydrateProductNames(productSupabaseNames));
    //   if (productSupabaseNames) {
    //     return {
    //       productNames: productSupabaseNames

    //     }
    //   } else {
    //     return{
    //       productNames: nameProduct
    //     }

    // }

    // },renderCell: (params: GridRenderCellParams) => (
    //   <Stack
    //   spacing={2}
    //       direction="row"
    //       sx={{ width: "100%" }}
    //       alignItems="left"
    //     >
    //       <Typography variant="body2" component="div" align="left" width={100}>
    //     {params.value.productNames}

    //   </Typography>
    //   </Stack>)



    // },

    // { header_name: "Code", field: "code", width: 120 },
    { headerName: "Quantity", field: "quantity", width: 100,
     valueGetter: (params: GridRenderCellParams) => {
    const supaQuantities = campaignData[params.row.code];
    return {
      supaQuantity: supaQuantities?.quantity }





  },
  renderCell: (params: GridRenderCellParams) => (
    <Stack
    spacing={2}
        direction="row"
        sx={{ width: "100%" }}
        alignItems="center"
      >
        <Typography variant="body2" component="div" align="center" width={100}>

        {params.value.supaQuantity}
        </Typography>
    </Stack>)



  },

    { headerName: "Current Markup", field: "current_margin", valueGetter: (params: GridRenderCellParams) => {
      // const subclassMarkup = subclassMarkupFilter[params.row.code];
      const currentMarginProduct = current_margin_product[params.row.code];
      //subclassMarkup ===  "Outside (Reference) Lab Revenue"
      if (currentMarginProduct !== null && currentMarginProduct !== 0) {
      return {
        currentMarginProduct: `${currentMarginProduct} %`
      };
    } else {
      return {
        currentMarginProduct: " "
      };
    }
  },
      renderCell: (params: GridRenderCellParams) => (
      <Stack
      spacing={2}
          direction="row"
          sx={{ width: "100%" }}
          alignItems="center"
        >
          <Typography variant="body2" component="div" align="center" width={100}>

          {params.value.currentMarginProduct}
          </Typography>
      </Stack>)



    },
    {
      headerName: "Price Slider",
      field: "product_price",
      valueGetter: (params: GridRenderCellParams) => {
        const supaOrgs = campaignData[params.row.code];
        const supaOrg = supaOrgs?.original_price;
        const supaTuned = supaOrgs?.tuned_price;
        const orgTuned = supaTuned !== null ? supaTuned : supaOrg;
        return {
          old_price: supaOrg,
          max_price:
            orgTuned < 10
              ? orgTuned * 3
              : orgTuned * 1.15,
            new_price: _.round(
              tunedPrices[params.row.code] || supaOrg,
              2)}

  },

      width: 360,
      renderCell: (params: GridRenderCellParams) => (
        <Stack
          spacing={2}
          direction="row"
          sx={{ width: "100%" }}
          alignItems="center"
        >
          <Typography variant="body2" component="div" align="left" width={80}>

            {$(params.value.old_price)}
          </Typography>


          <Slider
            min={params.value.old_price}
            max={params.value.max_price}
            step={0.1}
            value={params.value.new_price}
            onChange={(e, newValue) =>
              updatePriceSlider(params.row.code, newValue as number)
            }
          />
          <Input
            value={params.value.new_price}
            size="small"
            sx={{ width: "100px" }}
            onChange={(e) =>
              handleInputChange(params.row.code, Number(e.target.value))
            }

            //   onBlur={handleBlur}
            inputProps={{
              value: params.value.new_price,
              min: params.value.old_price,
              max: params.value.max_price,
              step: 0.1,
              type: "number",
              "aria-labelledby": "input-slider",
              style: { fontSize: "0.9rem" },
            }}
          />
        </Stack>
      ),
    },
    { headerName: "Adjusted Markup", field: "adjusted_margin", valueGetter: (params: GridRenderCellParams) => {
      // const subclassMarkup = subclassMarkupFilter[params.row.code];
      const adjustedMarginProduct = adjusted_margin_product[params.row.code];
      //subclassMarkup === "Outside (Reference) Lab Revenue" &&
      if (adjustedMarginProduct !== null &&  adjustedMarginProduct !== 0) {
      return {
        adjustedMarginProduct: `${adjustedMarginProduct} %`
      };
    } else {
      return {
        adjustedMarginProduct: " "
      };
    }
  },
      renderCell: (params: GridRenderCellParams) => (
      <Stack
      spacing={2}
          direction="row"
          sx={{ width: "100%" }}
          alignItems="center"
        >
          <Typography variant="body2" component="div" align="center" width={80}>

          {params.value.adjustedMarginProduct}
          </Typography>
      </Stack>)



    },
    // { headerName: "Adjusted Margin", field: "adjusted_margin_product", width: 100 },
    { headerName: "Subclass", field: "uvc_subclass", width: 160, hidden: true },
  ];

  function generateFilters() {
    if (searchTerm.length > 0) {
      return {
        items: [
          {
            id: 1,
            columnField: "product_name",
            operatorValue: "contains",
            value: searchTerm,
          },
        ],
      };
    } else if (filterSubclass.length > 0) {
      return {
        items: [
          ...filterSubclass.map((e, i) => ({
            id: i,
            columnField: "uvc_subclass",
            operatorValue: "contains",
            value: e,
          })),
        ],
        linkOperator: GridLinkOperator.Or,
      };
    } else return { items: [] };
  }

  const filters: GridFilterModel = {
    ...generateFilters(),
  };

  function applyPercIncrease() {
    const perc = increaseCatPerc;
    handleSliderChange();

    const filtered_codes =
      filterSubclass.length === 0
        ? impactDatas?.filter(
            (code: any) => code.code_type === "Service"
          )
        : impactDatas?.filter(
            (code: any) =>
              filterSubclass.includes(code.uvc_subclass) &&
              code.code_type === "Service"
          );

    filtered_codes.forEach((code: any) => {
      const new_price = code.original_price * (1 + perc / 100);
      dispatch(tunePrice({ code: code.code, tuned_price: new_price }));



    });
  }

  function handleReset() {
    const filtered_codes =
      filterSubclass.length === 0
        ? impactDatas
        : impactDatas.filter(
            (code: any) =>
              filterSubclass.includes(code.uvc_subclass) &&
              code.code_type === "Service"
          );

    filtered_codes.forEach((code: any) => {

      dispatch(tunePrice({ code: code.code, tuned_price: code.original_price}));

    });
  }

  return (
    <Container sx={{ marginTop: "1em" }} maxWidth={false}>
      <Box sx={{ paddingBottom: "1em", width: "flex-content" }}>
        <Stepper activeStep={0} alternativeLabel sx={{ width: "flex-content" }}>
          <Step key="product">
            <StepLabel>Set your product fees</StepLabel>
          </Step>
          <Step key="services">
            <StepLabel>Tune your service fees</StepLabel>
          </Step>
        </Stepper>
      </Box>
      <Grid container direction="row" spacing={0}>
        <Grid item xs="auto">
          <Card
            sx={{
              marginBottom: "1em",
              width: "90%",
              padding: ".5em",
            }}
          >
             <Typography variant="subtitle1" component="div" align="left" fontWeight="medium" paddingBottom="1em">
             {campaignName} Campaign&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            </Typography>

            <Typography variant="subtitle1" component="div" align="left">
              Target&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {$(target)}
            </Typography>

            <Typography variant="body2" component="div" align="left">
              Pricing
              impact&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {$(pure_price_impact)}
            </Typography>

            <Typography variant="body2" component="div" align="left">
              Disp. fee impact&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {$(dispensing_fee_impact)}
            </Typography>

            <Typography variant="body2" component="div" align="left">
              Min price impact&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {$(minimum_price_impact)}
            </Typography>

            <Typography
              variant="subtitle1"
              component="div"
              align="left"
              color={current > target ? "green" : "red"}
            >
              Current &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {$(current)} */}
              {/* <br /> */}
              {$(current)}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              align="left"
              paddingTop="1em"
              // color={current > target ? "green" : "red"}
            >
              Outside Lab Markup Target &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              { _.round(target_margin*100, 2)}%
            </Typography>
            <Typography
              variant="body2"
              component="div"
              align="left"
              color={outside_lab_margin > target_margin ? "green" : "red"}


            >
              Outside Lab Markup &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              { _.round(outside_lab_margin, 2)}%
            </Typography>

          </Card>
          <Card
          sx={{
            marginBottom: "1em",
            width: "90%",
            padding: "1em",
          }}>
          <Typography
              variant="body2"
              component="div"
              align="left"
              width={240}
              sx={{ marginTop: "0em" }}
            >
              Minimum Prescription Price - ${minimumPrice}
            </Typography>
            <Slider
              min={0}
              max={40}
              step={1}
              value={minimumPrice}
              onChange={(e, newValue) =>
                dispatch(setMinimumPriceSlider(newValue as number))
              }
              onChangeCommitted={handleSliderChange}
            />

            <Typography
              variant="body2"
              component="div"
              align="left"
              width={240}
              sx={{ marginTop: "0em" }}
            >
              Minimum Dispensing Fee - ${minimumDispensingFee}
            </Typography>
            <Slider
              min={0}
              max={40}
              step={1}
              value={minimumDispensingFee}
              onChange={(e, newValue) =>
                dispatch(setMinimumDispensingFee(newValue as number))
              }
              onChangeCommitted={handleSliderChange}
            />
            <Typography
              variant="body2"
              component="div"
              align="left"
              width={240}
              sx={{ marginTop: "0em" }}
            >
              Minimum Handling Fee - ${minimumHandlingFee}
            </Typography>
            <Slider
              min={0}
              max={40}
              step={1}
              value={minimumHandlingFee}
              onChange={(e, newValue) =>
                dispatch(setMinimumHandlingFee(newValue as number))
              }
              onChangeCommitted={handleSliderChange}
            />
            <Typography
              variant="body2"
              component="div"
              align="left"
              width={240}
              sx={{ marginTop: "0em" }}
            >
              Dispensing Fee Increase - ${dispensingFeeIncrease}
            </Typography>
            <Slider
              min={0}
              max={40}
              step={1}
              value={dispensingFeeIncrease}
              onChange={(e, newValue) =>
                dispatch(setDispensingFeeIncrease(newValue as number))
              }
              onChangeCommitted={handleSliderChange}
            />
           </Card>
           <Card
           sx={{
            marginBottom: "1em",
            width: "90%",
            padding: "1em",
          }}>

            <Typography
              variant="body2"
              component="div"
              align="left"
              width={240}
              sx={{ marginTop: "0em" }}
            >
              Minimum Injection Price - ${minimumInjectionPrice}
            </Typography>
            <Slider
              min={0}
              max={80}
              step={1}
              value={minimumInjectionPrice}
              onChange={(e, newValue) =>
                dispatch(setMinimumInjectionPrice(newValue as number))
              }
              onChangeCommitted={handleSliderChange}
            />

            <Typography
              variant="body2"
              component="div"
              align="left"
              width={240}
              sx={{ marginTop: "0em" }}
            >
              Minimum Injection Fee - ${minimumInjectionFee}
            </Typography>
            <Slider
              min={0}
              max={80}
              step={1}
              value={minimumInjectionFee}
              onChange={(e, newValue) =>
                dispatch(setMinimumInjectionFee(newValue as number))
              }
              onChangeCommitted={handleSliderChange}
            />
            <Typography
              variant="body2"
              component="div"
              align="left"
              width={240}
              sx={{ marginTop: "0em" }}
            >
              Injection Fee Increase - ${injectionFeeIncrease}
            </Typography>
            <Slider
              min={0}
              max={80}
              step={1}
              value={injectionFeeIncrease}
              onChange={(e, newValue) =>
                dispatch(setInjectionFeeIncrease(newValue as number))
              }
              onChangeCommitted={handleSliderChange}
            />


            <Stack direction="row" spacing={2} alignItems="center">
              <Button variant="outlined" onClick={handleSave}>
                Save
              </Button>

              <Button variant="outlined" onClick={handleExport} disabled={!isSaving || hasChanges}>
                Export
              </Button>


              <Button variant="outlined" onClick={handleReset}>
                Reset
              </Button>
            </Stack>
            {/* <Stack direction="row">
              {isSaving && <CircularProgress size={24} />}
            </Stack> */}
          </Card>
</Grid>

<Grid item xs={9}>
  {supLoading  && priceLoading ?(
    <CircularProgress />
  ) : (
          <><Box
                sx={{
                  width: "100%",
                  display: "flex",
                  marginBottom: "1em",
                  paddingLeft: ".5em"
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{ width: "300px", marginTop: "1em" }} />
                <Box
                  sx={{
                    width: "fit-content",
                    display: "flex",
                    marginBottom: "1em",
                    marginLeft: "2em",
                    backgroundColor: "#fff",
                    boxShadow: "1",
                    borderRadius: "5px"
                  }}>
                  <FormControl sx={{ width: "300px", marginBottom: "1em", marginTop: "1em", marginLeft: "1em" }}>
                    <InputLabel id="demo-simple-select-label">Subclass</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      multiple
                      id="demo-simple-select"
                      value={filterSubclass}
                      renderValue={(selected) => selected.join(", ")}
                      label="Subclass"
                      onChange={(value) => {
                        setFilterSubclass(value.target.value as string[]);
                      } }
                      endAdornment={<IconButton
                        sx={{
                          display: filterSubclass.length > 0 ? "" : "none",
                        }}
                        onClick={() => setFilterSubclass([])}
                      >
                        <ClearIcon />
                      </IconButton>}
                    >
                      {_.uniq(
                        impactDatas?.map((item: any): string => item.uvc_subclass || "")
                          .sort()
                        // @ts-ignore
                      ).map((item: string) => {
                        return (
                          <MenuItem key={item} value={item}>
                            <Checkbox checked={filterSubclass.indexOf(item) > -1} />
                            <ListItemText primary={item} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {/* <Autocomplete
      multiple
      id="tags-standard"
      options={_.uniq(
        data.evo_pricing_pricing_with_usage
          .map((item: any): string => item.uvc_subclass || "")
          .sort()
      )}
      value={filterSubclass}
      onChange={(event, value) =>
        setFilterSubclass(value as string[])
      }
      getOptionLabel={(option: any) => option}
      defaultValue={[]}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          // label="Subclasses"
          placeholder="Subclasses"
        />
      )}
    /> */}
                  </FormControl>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    label="% inc"
                    variant="outlined"
                    value={increaseCatPerc.toString()}
                    onChange={(value) => {
                      if (value.target.value === "") {
                        setIncreaseCatPerc(0);
                        return;
                      }
                      setIncreaseCatPerc(parseFloat(value.target.value));
                    } }
                    sx={{ marginLeft: "1em", width: "100px", marginTop: "1em" }}
                    inputProps={{
                      maxLength: 4,
                      step: "0.1",
                      style: {
                        fontSize: "1em",
                      },
                    }} />
                  <Button
                    variant="outlined"
                    sx={{ marginLeft: "1em", marginTop: "1em", marginBottom: "1em", marginRight: "1em" }}
                    onClick={applyPercIncrease}

                  >
                    Apply
                  </Button>
                </Box>
              </Box><Box sx={{ height: "calc(100vh - 160px)", width: "flex-content", marginRight: "-4em", marginLeft: ".4em" }}>
                  <DataGridPro
                    density="compact"
                    rows={gridData}

                    //   data.evo_pricing_pricing_with_usage_hospital_group.filter((e: any) => {
                    //   return e.code_type === "Service" && (!tunedPrices || tunedPrices.code === e.code);
                    //   })
                    //   }
                    // initialState={{
                    //   sorting: {
                    //     sortModel: [{ field: "quantity", sort: "desc" }],
                    //   }
                    // }}
                    filterModel={filters}
                    // />
                    // components={{ Toolbar: GridToolbar }}
                    // componentsProps={{
                    //   toolbar: {
                    //     showQuickFilter: false,
                    //     quickFilterProps: { debounceMs: 500 },
                    //   },
                    // }}
                    columns={columns}
                    getRowId={(e) => e.code}
                    getRowClassName={(params) => {
                      const isChanged = tunedPrices.hasOwnProperty(params.row.code);

                      return isChanged ? "price-row-changed" : "";
                    } } />
                </Box></>)};
        </Grid>
              </Grid>
    </Container>
  );
  };

export default HospitalPriceEditor;
