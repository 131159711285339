import { CodeRounded, CodeSharp } from "@mui/icons-material";
import { setMinimumHandlingFee} from "../../features/pricingTool/pricingToolSlice";
import exclusions from "./subcat_exclusions";
import _ from "lodash";
import { current } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../hooks";


const exclusion_ids = exclusions.map((exclusion) => exclusion.uvc_subcategory_id);



export interface Supa {
  code: string;
  quantity: number;
  instances: number;
  list_price: number;
  original_price: number;
  original_min_price: number;
  original_dispensing_fee: number;
  uvc_subclass: string;
  uvc_subcategory: string
  uvc_subcategory_id: number;
  product_name: string;
  net_amount: number;
  pms_code: string;
  code_type: string;
} export interface fees {
  minimumDispensingFee: number;
  minimumInjectionFee: number;
  dispensingFeeIncrease: number;
  minimumPrice: number;
  minimumHandlingFee: number;
  minimumInjectionPrice: number;
  injectionFeeIncrease: number;
}

export interface ITuned {
  tuned_price: number;
}


type FeeType = "DispensingFee" | "HandlingFee" | "InjectionFee" | "OtherFee" | "Excluded"

// Extract getDispensingFeeType from getDispensingFee
function getDispensingFeeType(code: Supa): FeeType {
  const average_quantity = code.quantity / code.instances;
  if (exclusion_ids.includes(code.uvc_subcategory_id)) {
    console.log("Excluded: " + code.uvc_subcategory + " due to excluded class " + exclusions.find(e => e.uvc_subcategory_id === code.uvc_subcategory_id)?.uvc_subcategory);
    return "Excluded"
  }
  // Should use dispensing fee
  else if (code.uvc_subclass === "FDA Prescription Meds" && average_quantity >= 3) {
    return "DispensingFee"
  }
  // Should use handling fee
  else if (code.uvc_subclass === "FDA Prescription Meds" && average_quantity < 3) {
    return "HandlingFee"
  }
  // Should use injection fee
  else if (code.uvc_subclass === "Injection Revenue") {
    return "InjectionFee"
  }
  else return "OtherFee"
}

export function getDispensingFee( code: Supa, {minimumDispensingFee, minimumHandlingFee, minimumInjectionFee, dispensingFeeIncrease, injectionFeeIncrease}: fees) {
  const feeType = getDispensingFeeType( code);
  switch (feeType) {
    case "DispensingFee":

      return minimumDispensingFee > code.original_dispensing_fee
        ? minimumDispensingFee
        : code.original_dispensing_fee + dispensingFeeIncrease;

    case "HandlingFee":
      return minimumHandlingFee > code.original_dispensing_fee
        ? minimumHandlingFee
        : code.original_dispensing_fee + dispensingFeeIncrease;

    case "InjectionFee":
      return minimumInjectionFee > code.original_dispensing_fee
        ? minimumInjectionFee
        : code.original_dispensing_fee + injectionFeeIncrease;

    case "Excluded":
      return code.original_dispensing_fee

    default:
      return code.original_dispensing_fee
  }
}


export function getMinimumPrice(code: Supa, {minimumPrice}: fees) {
  if (code.original_min_price > 1000) { debugger }
  if (code.uvc_subclass === "FDA Prescription Meds") {
    return minimumPrice > code.original_min_price ? minimumPrice : code.original_min_price;
  }
  else return code.original_min_price;
}
export function getMinimumInjectionPrice(code: Supa, {minimumInjectionPrice}: fees) {
  if (code.original_min_price > 1000) { debugger }
  if (code.uvc_subclass === "Injection Revenue") {
    return minimumInjectionPrice > code.original_min_price ? minimumInjectionPrice : code.original_min_price;
  }
  else return code.original_min_price;
}


export function getMinimumPrices(code: Supa, {minimumPrice, minimumInjectionPrice}: fees) {
  if (code.uvc_subclass === "FDA Prescription Meds" && minimumPrice > code.original_min_price) {
    return minimumPrice
  }
  else if (code.uvc_subclass === "Injection Revenue"  && minimumInjectionPrice > code.original_min_price) {
    return minimumInjectionPrice
  }
  else return code.original_min_price;
}



export interface ICodeImpact {
  code: any;
  new_calulated_price: number;
  current_impact: number;
  total_impact2: number;
  pure_price_impact: number
  dispensing_fee_impact: number;
  minimum_price_impact: number
  tuned_revs: number
  sum_list_cost: number
}

export interface ICodeImpactFull extends ICodeImpact{
  code: string
  instances: number
  list_price: number
  old_min_price: number
  new_calulated_price: number
  current_impact: number
  adjusted_margin: number
  adjusted_margin_products: number
  current_margin_products: number
  disp_impact: number
  average_quantity: number
  base_price: number
  calculated_price: number
  new_dispensing_fee: number
  tuned_price: number
  new_min_price: number
  calc_new_min_price: number
  new_base_price: number
  price_difference: number
  dispensing_fee_impact: number
  dispensing_fee_difference: number
  price_impact: number
  minimum_price_impact: number
  pure_price_difference: number
  pure_price_impact: number
  new_min_dispensing_fee: number
  dispensing_fee_impact_inj: number
  price_impact_new: number
  final: number
  tuned_revs: number
  sum_list_cost: number
  original_price: number
  quantity: number
  original_min_price: number
  original_dispensing_fee: number
  product_name: string;
  net_amount: number;
  pms_code: string;
  code_type: string
}

export function CalculateSupaFields(code: Supa, {  minimumHandlingFee, minimumDispensingFee, minimumInjectionFee, dispensingFeeIncrease, injectionFeeIncrease, minimumInjectionPrice, minimumPrice }: fees, tuned: ITuned) {
  const average_quantity = code.quantity / code.instances;
  const weighted_price = (code.original_price) * average_quantity;
  const calculated_price = code.original_min_price > (weighted_price + code.original_dispensing_fee) ? code.original_min_price : (weighted_price + code.original_dispensing_fee);
  const tuned_price = tuned.tuned_price;
  const original_price = code.original_price
  const original_dispensing_fee = code.original_dispensing_fee
  const uvc_subclass = code.uvc_subclass
  const uvc_subcategory = code.uvc_subcategory
  const uvc_subcategory_id = code.uvc_subcategory_id
  const quantity = code.quantity
  const original_min_price = code.original_min_price
  const product_name = code.product_name
  const pms_code = code.pms_code
  const net_amount = code.net_amount
  const code_type = code.code_type




  const new_dispensing_fee = getDispensingFee( code,  { minimumHandlingFee, minimumDispensingFee, minimumInjectionFee, dispensingFeeIncrease, injectionFeeIncrease, minimumInjectionPrice, minimumPrice });
  const new_min_price = getMinimumPrices(code, { minimumHandlingFee, minimumDispensingFee, minimumInjectionFee, dispensingFeeIncrease, injectionFeeIncrease, minimumInjectionPrice, minimumPrice });




  const new_weighted_price = tuned_price * average_quantity;
  const new_calulated_price = new_min_price > (new_weighted_price + new_dispensing_fee) ? new_min_price : (new_weighted_price + new_dispensing_fee);
  const isMinPriceImpact = new_min_price >= (new_weighted_price + new_dispensing_fee);

  const loaded_price_difference = new_calulated_price - calculated_price;
  const dispensing_fee_difference = new_dispensing_fee - code.original_dispensing_fee;
  const pure_price_difference = !isMinPriceImpact ? new_weighted_price - weighted_price : 0;


  const expected_revenue_current = original_price ;
  const total_list_cost = code.list_price ;
  const expected_revenue_adjusted = tuned_price ;



  const tuned_revs = code.uvc_subclass === "Outside (Reference) Lab Revenue" && code.list_price != null  && code.list_price > 0 ? code.quantity * tuned_price : 0
  const sum_list_cost = code.list_price * code.quantity;


    function calcMinPriceImpact() {
      if (isMinPriceImpact) {
        if (code.original_min_price > (new_weighted_price + new_dispensing_fee)) {
          return new_min_price - code.original_min_price
        }
        else return new_min_price - (new_weighted_price + new_dispensing_fee)
      }
      else return 0
    }



//Impact calculations
  const minimum_price_impact = calcMinPriceImpact() * code.instances
  const price_impact = !isMinPriceImpact ? ((tuned_price - original_price) * code.quantity) : 0
  const dispensing_fee_impact = !isMinPriceImpact ? dispensing_fee_difference * code.instances  : 0 ;
  const current_impact = price_impact + minimum_price_impact + dispensing_fee_impact;


  const total_impact2 = (new_calulated_price - calculated_price) * code.instances


  const current_margin_product = code.uvc_subclass === "Outside (Reference) Lab Revenue" ? ((expected_revenue_current / total_list_cost)-1)*100 : 0;
  const current_margin_products = current_margin_product !== Infinity ? current_margin_product : 0;
  const adjusted_margin_product = code.uvc_subclass === "Outside (Reference) Lab Revenue" ? ((expected_revenue_adjusted / total_list_cost)-1)*100 : 0;
  const adjusted_margin_products = adjusted_margin_product !== Infinity ? adjusted_margin_product : 0;





    return {
      code: code.code,
      instances: code.instances,
      list_price: code.list_price,
      new_calulated_price,
      current_impact,
      total_impact2,
      current_margin_products: _.round(current_margin_products, 2),
      adjusted_margin_products: _.round(adjusted_margin_products, 2),
      original_min_price,
      original_dispensing_fee,
      uvc_subclass,
      uvc_subcategory,
      uvc_subcategory_id,
      product_name,
      pms_code,
      net_amount,
      code_type,
      quantity,
      average_quantity,
      base_price: weighted_price,
      calculated_price,
      tuned_revs,
      sum_list_cost,
      new_dispensing_fee,
      new_min_dispensing_fee: new_dispensing_fee,
      tuned_price,
      original_price,
      new_min_price,
      new_base_price: new_weighted_price,
      price_difference: loaded_price_difference,
      dispensing_fee_impact,
      dispensing_fee_difference,
      // calc_new_min_price,
      price_impact,
      minimum_price_impact,
      pure_price_difference,
      pure_price_impact: pure_price_difference * code.instances,
      // dispensing_fee_impact_inj,
      // price_impact_ect
    }};
