import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import { Navigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //   ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  verticalAlign: "center",
  color: theme.palette.text.secondary,
  height: "128px",
}));

function BasicCard({
  title,
  description,
  link,
}: {
  title: string;
  description: string;
  link?: string;
}) {
  return (
    <Card
      onClick={() => window.open(link, "_blank")}
      sx={{ height: "148px" }}
      elevation={2}
    >
      <CardActionArea>
        <CardContent>
          <Typography variant="h5" component="div">
            {title} ➔
          </Typography>
          <Typography variant="body2" paddingTop="1em">
            {description}
          </Typography>
        </CardContent>
        {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
      </CardActionArea>
    </Card>
  );
}

const learningPageLinks = [
  {
    link: "https://launcher.myapps.microsoft.com/api/signin/12ab5d4c-283f-4f43-9d84-c4973e739355?tenantId=ce76298f-68d5-4b4c-8ace-1f905bdd4538",
    subject: "VetBloom",
    body: "View your assigned courses, and browse other available courses",
  },
  {
    link: "https://unitedvetcare.talentlms.com",
    subject: "Talent LMS",
    body: "View your assigned courses, and browse other available courses",
  },
];

export default function LearningPage() {
  return (
    <Container maxWidth="md" style={{ paddingTop: "2em" }}>
      <Grid container spacing={2} maxWidth="lg">
        {learningPageLinks.map((e) => (
          <Grid item lg={4} md={4} sm={12} xs={12} key={e.link}>
            <BasicCard title={e.subject} description={e.body} link={e.link} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
